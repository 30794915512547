import Image from "next/image";
import ServiceCard from "./ServiceCard";
import Animate from "../Common/AnimateOnScroll";
import Link from "next/link";
import SliderButtons from "../Common/SliderButtons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";

const Services = () => {
  const services = [
    {
      title: "Landing Page Development",
      desc: "Unlock the power of a compelling first impression with Zysoftec's professional Landing Page Design Services. Go with the professional Landing Page Developer agency Zysoftec.",
      img: "/images/webdev.png",
      link: "/services/landing-page-design-agency",
      alt: "Landing Page Development",
    },
    {
      title: "Website Development",
      desc: "Get your dream website ready tailored to your business needs. Our custom web development services can boost your online presence to scale your business.",
      img: "/images/landingpage.png",
      link: "/services/custom-web-development-services",
      alt: "Website Development",
    },
    {
      title: "Web Admin Panels",
      desc: "Get developed feature rich admin panels for your website or ecommerce store. We ensure seamless web admin panel designs aligned with your business requirement.",
      img: "/images/fullstack.png",
      link: "/services/admin-panel-development-services",
      alt: "Web Admin Panels",
    },
    {
      title: "API Integrations",
      desc: "We provide API integration services that will bring automation in your business. We can integrate APIs with your existing or new systems and applications for smooth operations.",
      img: "/images/frontend.png",
      link: "/services/API-integration-services",
      alt: "API Integrations",
    },
    {
      title: "Android App Development",
      desc: "Get ready your imagined android app by hiring android developers of Zysoftec. We ensure delivering top-notch android applications aligned with your business goals. ",
      img: "/images/api.png",
      link: "/services/Hire-android-developers",
      alt: "Android App Development",
    },
    {
      title: "IOS App Development",
      desc: `Our team of professional IOS developers ensures transforming your app ideas into responsive and high-performance IOS applications that captivate IOS user’s attention.`,
      img: "/images/api2.webp",
      link: "/services/Hire-ios-developers",
      alt: "3rd Party API Integration services",
    },
    {
      title: "UI/UX Designs",
      desc: "As a leading UI/UX design agency, we specialize in designing the best app UI designs or UI Ux web designs that are fully responsive and eye catching for the users.",
      img: "/images/adminpanel.png",
      link: "/services/UX/UI-Design-Solution",
      alt: "UI/UX Designs",
    },
    {
      title: "Search Engine Optimization",
      desc: "Get your website on top positions in the search engines to drive traffic, leads and sales. We offer complete SEO including On page, Off page and Technical SEO to rank your website.",
      img: "/images/frontendapp.png",
      link: "/services/Charleston-SEO-agency-simply-the-best",
      alt: "Charleston-SEO-agency-simply-the-best",
    },
  ];
  return (
    <div className="relative flex items-center justify-center flex-col space-y-4  px-8 xl:px-5 py-10 ">
      <Animate.ScaleIn>
        <div className="flex flex-col items-center space-x-2 space-y-2 text-center ">
          <div className="flex items-center space-x-2 text-center fontNew">
            <p className={`text-2xl xl:text-5xl font-bold text-secondary `}>
              Our Top
            </p>
            <p className={`text-2xl xl:text-5xl font-bold text-primary `}>
              Services
            </p>
          </div>
          <span className="text-base font-normal max-w-[80%] text-center fontNew">
            ZySoftec provides software solutions and websites which are
            scalable, reliable, fast and secure.
          </span>
        </div>
      </Animate.ScaleIn>
      {/* <Animate.ScaleIn> */}
      <div className="max-w-[300px] md:max-w-screen-xl mx-auto">
        <div className="flex flex-col justify-center items-center">
          <div className="hidden  sm:hidden grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 gap-10 md:gap-10">
            {/* {services?.map((service, i) => (
              <div key={i}>
                <ServiceCard
                  title={service?.title}
                  alt={service?.alt}
                  desc={service?.desc}
                  img={service?.img}
                  btnBg={i % 2 === 0 ? "#FA7070" : "#16349F"}
                  link={service?.link}
                />
              </div>
            ))} */}
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              freeMode={false}
              grabCursor={true}
              modules={[Pagination, Autoplay]}
              autoplay
              className="mySwiper"
              breakpoints={{
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                0: {
                  slidesPerView: 1,
                  spaceBetween: 50,
                },
              }}
              style={{
                width: "100%",
                paddingTop: "4rem",
                paddingBottom: "4rem",
              }}
              id="case-study-slider"
            >
              <SliderButtons />
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 ">
                {services?.map((service, i) => (
                  <SwiperSlide key={service.link}>
                    <ServiceCard
                      title={service?.title}
                      alt={service?.alt}
                      desc={service?.desc}
                      img={service?.img}
                      btnBg={i % 2 === 0 ? "#FA7070" : "#16349F"}
                      link={service?.link}
                    />
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
          </div>
          <div className=" sm:grid grid-cols-1  sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 gap-10 md:gap-10">
            {services?.map((service, i) => (
              <div key={i}>
                <ServiceCard
                  title={service?.title}
                  alt={service?.alt}
                  desc={service?.desc}
                  img={service?.img}
                  btnBg={i % 2 === 0 ? "#FA7070" : "#16349F"}
                  link={service?.link}
                />
              </div>
            ))}
          </div>
          <Link href={"/services"}>
            <div className="mt-10">
              <button className="text-white  flex gap-3 px-6 py-3 items-center justify-center font-[400] text-[16px] rounded-md bg-primary">
                View All Services
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 14 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.27729e-05 4.50063C8.27671e-05 4.63324 0.0527609 4.76042 0.146529 4.85418C0.240297 4.94795 0.367474 5.00063 0.500083 5.00063L12.2931 5.00063L9.14608 8.14663C9.0522 8.24052 8.99945 8.36786 8.99945 8.50063C8.99945 8.63341 9.0522 8.76074 9.14608 8.85463C9.23997 8.94852 9.36731 9.00126 9.50008 9.00126C9.63286 9.00126 9.7602 8.94852 9.85408 8.85463L13.8541 4.85463C13.9006 4.80819 13.9376 4.75301 13.9628 4.69226C13.988 4.63152 14.001 4.5664 14.001 4.50063C14.001 4.43486 13.988 4.36974 13.9628 4.309C13.9376 4.24825 13.9006 4.19308 13.8541 4.14663L9.85408 0.146631C9.8076 0.100143 9.75241 0.0632673 9.69167 0.0381082C9.63093 0.0129491 9.56583 -1.93867e-07 9.50008 -1.9674e-07C9.36731 -2.02544e-07 9.23997 0.0527446 9.14608 0.146631C9.0522 0.240518 8.99945 0.367856 8.99945 0.500631C8.99945 0.633407 9.0522 0.760745 9.14608 0.854631L12.2931 4.00063L0.500083 4.00063C0.367474 4.00063 0.240297 4.05331 0.146529 4.14708C0.0527609 4.24085 8.27787e-05 4.36802 8.27729e-05 4.50063Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </Link>
        </div>
      </div>
      {/* </Animate.ScaleIn> */}
      <Image
        src={"/images/tech-circle.svg"}
        width={100}
        height={100}
        className="absolute left-0 -top-10 -z-10 md:w-[300px] md:h-[300px] xl:w-[450px] xl:h-[450px]"
        alt="services"
      />
      {/* <Image
        src={"/images/client-ellipse.svg"}
        width={113}
        height={113}
        className="absolute right-0 xl:right-36 -top-5 -z-10"
        alt="services"
      /> */}
    </div>
  );
};

export default Services;
