import Image from "next/image";
import Link from "next/link";
import { Kumbh_Sans } from "next/font/google";
import Animate from "../Common/AnimateOnScroll";


const kumbhsans = Kumbh_Sans({
  subsets: ["latin"],
  weight: ["700"],
});

const ServiceCard = ({ img, title, desc, btnBg, link, alt }) => {
  return (
    <Animate.ScaleIn>
    <div className="bg-[#F2F6FF] rounded-[30px] shadow-md flex flex-col space-y-4 items-center justify-start py-10 relative  h-[350px] px-2 hover:scale-[1.1] transition-all duration-500 my-10 sm:my-0">
      <div className="relative">
        <Image
          src={"/images/vector-bg.png"}
          width={100}
          height={100}
          alt="vector"
          className="max-w-[50px] max-h-[74px]"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
        <Image
          src={img}
          width={500}
          height={500}
          alt="services"
          className="max-w-[35px] max-h-[40px]  service-card-img"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </div>
      <p
        className={`text-primary flex justify-start items-center text-center font-semibold text-2xl `}
      >
        {title}
      </p>
      <span className="text-sm flex flex-col justify-center items-center font-normal text-center px-2 fontNew">
        {desc}
      </span>
      <Link
        href={link}
        className="w-[50px] h-[50px] hover:w-[55px] hover:h-[55px] hover:ease-in hover:duration-200  hover:-translate-y-3 p-4 rounded-full flex items-center justify-center absolute -bottom-5 "
        style={{ background: btnBg }}
        aria-label={title}
      >
        <Image
          alt={alt}
          src={"/images/service-card-arrow.svg"}
          width={24}
          height={24}
          className="scale-100 hover:scale-120 hover:ease-in hover:duration-200"
        />
      </Link>
    </div>
    </Animate.ScaleIn>
  );
};

export default ServiceCard;
